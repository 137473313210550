<template>
  <header ref="header" class="header">
    <div class="header__container">
      <NuxtLink to="/" class="header__logo">
        <NuxtImg src="/img/main_logo-alt.svg" />
      </NuxtLink>
      <div class="header__right-section">
        <NuxtLink
          class="mob_rm"
          :to="'https://www.kp.ru/putevoditel/spetsproekty/edtech-itogi-2024/'"
          target="_blank"
        >
          Итоги 2024
        </NuxtLink>
        <button class="primary text-12" @click="togglePartModal(true)">
          Стать участником
        </button>
        <ClientOnly>
          <HeaderSelect
            v-if="nominationsData"
            v-model="selectedNomination"
            class="header__select"
            placeholder="Номинации"
            :options="nominationsData"
          />
        </ClientOnly>
        <button class="burger" @click="toggleMenu">
          <NuxtImg src="/img/icon_burger.svg" />
        </button>
        <nav ref="navbar" class="navbar">
          <button class="navbar__close" @click="toggleMenu">
            <NuxtImg src="/img/icon_close.svg" />
          </button>
          <div v-if="authEnabled" class="header__auth">
            <button
              v-if="!session?.user?.username"
              class="navbar__signIn"
              @click="openSignInModal"
            >
              Войти
            </button>
            <h4 v-else class="navbar__signIn">
              {{ session.user.username }}
            </h4>
          </div>
          <div
            class="header__nominations"
            :class="{ showNominations: showNominations }"
          >
            <button @click="showNominations = !showNominations">
              <span>Номинации</span>
              <NuxtImg
                :class="{ showNominations: showNominations }"
                src="/img/icon_white_arrow.svg"
              />
            </button>
            <ul v-if="showNominations">
              <li v-for="(nomination, id) in nominationsData" :key="id">
                <NuxtLink
                  :to="`/nomination-${nomination.attributes.slug}`"
                  @click="closeMenu()"
                >
                  {{ nomination.attributes.shortTitle }}
                </NuxtLink>
              </li>
            </ul>
          </div>
          <ul>
            <li>
              <NuxtLink :to="{ path: '/' }">Главная</NuxtLink>
            </li>
            <li>
              <NuxtLink :to="{ path: '/', hash: '#about' }">О премии</NuxtLink>
            </li>
            <li class="nominations">
              <NuxtLink :to="{ path: '/', hash: '#nominations' }">
                Номинации
              </NuxtLink>
            </li>
            <li>
              <NuxtLink :to="{ path: '/', hash: '#experts' }">
                Эксперты
              </NuxtLink>
            </li>
            <li>
              <NuxtLink :to="{ path: '/', hash: '#review' }">Отзывы</NuxtLink>
            </li>
            <li>
              <NuxtLink :to="{ name: 'oferta' }">Положение</NuxtLink>
            </li>
            <li>
              <NuxtLink
                :to="'https://www.kp.ru/putevoditel/spetsproekty/edtech-itogi-2024/'"
                target="_blank"
              >
                Итоги 2024
              </NuxtLink>
            </li>
            <li v-if="authEnabled && loggedIn" class="header__auth">
              <button class="navbar__signIn" @click="logout">Выйти</button>
            </li>
          </ul>
          <div class="navbar__socials">
            <NuxtLink
              v-for="social in socialsData"
              :key="social.id"
              :to="social?.link"
              target="_blank"
            >
              <img
                class="social-icon"
                :src="useStrapiMediaUrl(social?.icon?.data?.attributes?.url)"
                loading="lazy"
              />
            </NuxtLink>
          </div>
          <Share in-header> Поделиться </Share>
        </nav>
      </div>
    </div>
    <Teleport to="#teleports">
      <UiModal
        v-if="authEnabled && !loggedIn && isModalVisible"
        :on-close="toggleModal"
        :is-visible="isModalVisible"
      >
        <HeaderAuthorizationContent
          :is-visible="isModalVisible"
          :view="modalView"
          :close="toggleModal"
        />
      </UiModal>
      <UiModal
        v-if="isPartModalVisible"
        :on-close="togglePartModal"
        :is-visible="isPartModalVisible"
      >
        <ParticipantApplication :is-visible="isPartModalVisible" />
      </UiModal>
    </Teleport>
  </header>
</template>

<script setup lang="ts">
import { throttle } from "~/utils/common"
import { getNominations } from "~/backend/composables/base"
const { $gsap } = useNuxtApp()
const globalData = await getGlobals()
const nominationsData = await getNominations()
const selectedNomination = ref()
const isMenuOpen = useState<boolean>("isMenuOpen", () => false)
const navbar = ref<Element>()
const isModalVisible = useState<boolean>("isAuthModalVisible")
const isPartModalVisible = ref(false)
const route = useRoute()
const socialsData = computed(() => {
  return globalData?.attributes.socials
})
const showNominations = ref(false)
const togglePartModal = (value) => {
  isPartModalVisible.value = value
}
const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
  if (isMenuOpen.value) {
    $gsap.fromTo(
      navbar.value,
      { y: -100, opacity: 0 },
      { y: 0, opacity: 1, display: "flex", duration: 0.5, ease: "power3.out" },
    )

    return
  }

  $gsap.to(navbar.value, {
    y: -100,
    opacity: 0,
    display: "none",
    duration: 0.5,
    ease: "power3.in",
  })
}

const toggleModal = (value) => {
  isModalVisible.value = value
}

const openSignInModal = () => {
  toggleModal(true)
  toggleMenu()
}

const closeMenu = () => {
  isMenuOpen.value = false
  $gsap.to(navbar.value, {
    y: -100,
    opacity: 0,
    display: "none",
    duration: 0.5,
    ease: "power3.in",
  })
}
onMounted(() => {
  $gsap.set(navbar.value, { y: -100, opacity: 0, display: "none" })
})

const header = ref<Element>()
const startScroll = 100
const lastScroll = ref<number>(startScroll)
const hideNavOnScroll = function () {
  const scroll = window.pageYOffset || document.documentElement.scrollTop

  if (scroll <= startScroll) {
    header.value?.classList.remove("collapsed")
    return
  }

  if (lastScroll.value > scroll) {
    header.value?.classList.remove("collapsed")
  } else {
    header.value?.classList.add("collapsed")
  }

  lastScroll.value = scroll > startScroll ? scroll : startScroll
}

onMounted(() => {
  window.addEventListener("scroll", throttle(hideNavOnScroll, 120))
})
onBeforeUnmount(() => {
  window.removeEventListener("scroll", throttle(hideNavOnScroll, 120))
})

const { authEnabled, loggedIn, session } = useAuth()
const { logout } = useStrapiClient()
const modalView = useState<string | undefined>("authModalView")
const isEmailConfirm = "emailConfirmed" in route.query
const isPasswordReset = "resetPassword" in route.query

onMounted(() => {
  if (!isEmailConfirm && !isPasswordReset) return

  modalView.value = isPasswordReset ? "newPassword" : "login"
  toggleModal(true)
})
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  z-index: 9;
  width: 100%;
  max-width: 1372px;
  margin: 0 auto;
  top: 10px;
  left: 0;
  right: 0;
  padding: 0 36px;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 1px #9e85ff30;
  border-radius: 20px;
  transform: translateY(0);
  transition: transform 0.2s ease-in-out;

  &__container {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 90px;
    position: relative;
  }
  &__right-section {
    max-width: 630px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include md {
      max-width: 250px;
    }
  }
  & .mob_rm {
    @include md {
      display: none;
    }
  }
  &__select {
    @include md {
      display: none;
    }
  }

  &__logo {
    max-width: 190px;
    width: 100%;

    img {
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }
  &__nominations {
    display: none;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
    max-width: 208px;
    width: 100%;
    button {
      display: flex;
      max-width: 124px;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      img {
        min-width: 10px;
        min-height: 6px;
        &.showNominations {
          transform: rotate(180deg);
        }
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      gap: 14px;
      list-style: none;
      font-size: 12px;
      margin-left: 14px;
    }
    &.showNominations {
      margin-bottom: 40px;
    }

    @include md {
      display: flex;
    }
  }

  @include md {
    max-width: 96%;
    &__container {
      height: 88px;
    }
    &__auth {
      margin-bottom: 2em;
    }
  }
  @include sm {
    padding: 0 16px;
    &__container {
      height: 70px;
    }
  }
  &.collapsed {
    transform: translateY(-120%);
  }
}

.primary {
  border-radius: 60px;
  background: $color-purple;
  color: $white;
  text-transform: uppercase;
  height: 48px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @include sm {
    display: none;
  }
}

.burger {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $white;
  border-radius: 50%;
  filter: invert(42%) sepia(93%) saturate(7066%) hue-rotate(249deg)
    brightness(84%) contrast(92%);
  img {
    display: block;
    width: 20px;
    object-fit: cover;
  }
  @include sm {
    width: 42px;
    height: 42px;
    margin-left: auto;
  }
}

.navbar {
  position: absolute;
  max-width: 336px;
  width: 100%;
  min-height: 492px;
  padding: 40px;
  flex-direction: column;
  right: 0;
  top: 20px;
  background: $color-purple;
  border-radius: 20px;
  align-items: flex-start;
  color: $white;
  font-size: 16px;
  display: none;

  &.open {
    display: flex;
  }

  &__close {
    position: absolute;
    height: 33px;
    width: 33px;
    right: 10px;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      max-width: 12px;
    }
  }
  &__signIn {
    font-weight: 700;
  }
  ul {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    li {
      cursor: pointer;
    }
    @include md {
      margin-top: 0;
    }
  }
  &__socials {
    display: flex;
    gap: 6px;
    margin: 40px 0;
    a {
      width: 30px;
      height: 30px;
      background: $white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.5s;
    }
    a:hover {
      background-color: #e3e3e3;
    }
    .social-icon {
      display: block;
      object-fit: cover;
      filter: invert(42%) sepia(93%) saturate(7066%) hue-rotate(249deg)
        brightness(84%) contrast(92%);
    }
  }
  @include md {
    top: 16px;
  }
  @include sm {
    top: 12px;
    width: 100%;
    padding: 40px 29px 40px 40px;
  }
}

.nominations {
  display: block;
  @include md {
    display: none;
  }
}
</style>
